<template>
  <div>
    <!-- 基础信息 -->
    <div>
      <div class="top clearfix">
        <div class="content-header">
          基础信息
        </div>
        <div class="msgleft">
          <div class="pad">
            <span class="title">用户ID：</span>
            <span>{{userMsg.member_id?userMsg.member_id:''}}</span>
          </div>
          <div class="pad">
            <span class="title">用户姓名：</span>
            <span>{{userMsg.nickname}}</span>
          </div>
          <div class="pad">
            <span class="title">产生订单数：</span>
            <span>{{userMsg.order_num?userMsg.order_num:"0"}}</span>
            <i class="goorder" @click="goorder" v-if="userMsg.order_num !== 0">查看订单</i>
          </div>
          <div class="pad">
            <span class="title">最后登录的时间：</span>
            <span>{{userMsg.last_login | unixToDate("yyyy-MM-dd hh:mm:ss")}}</span>
          </div>
        </div>
        <div class="msgright">
          <div class="pad">
            <span class="title">手机号：</span>
            <span>{{userMsg.mobile?userMsg.mobile:""}}</span>
          </div>
          <div class="pad">
            <span class="title">加入方式：</span>
            <span>{{userMsg.join_way_name}}</span>
          </div>
          <div class="pad">
            <span class="title">注册时间：</span>
            <span>{{userMsg.create_time | unixToDate("yyyy-MM-dd hh:mm:ss")}}</span>
          </div>
        </div>
        <div class="msgtable" style="clear: both;">
          <div class="content-header">
            兑换记录
          </div>
          <en-table-layout
            :tableData="tableData.data"
            @selection-change="handleSelectionChange"
          >
            <template slot="toolbar">
              <div class="col-12" style="display: flex; justify-content: space-between; align-items: center">
                <div class="row no-gutters align-items-center">
                  <el-form-item label="兑换时间">
                    <el-date-picker
                      style="width: 250px"
                      v-model="inform.order_time_range"
                      type="daterange"
                      align="center"
                      size="medium"
                      :editable="false"
                      unlink-panels
                      range-separator="-"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                    ></el-date-picker>
                  </el-form-item>
                </div>
                <div class="row no-gutters align-items-center">
                  <el-form-item label="卡券名称">
                    <el-input
                      size="medium"
                      style="width: 180px"
                      v-model="inform.card_name"
                      placeholder="请输入卡券名称"
                      clearable
                    />
                  </el-form-item>
                  <el-button
                    size="small"
                    type="primary"
                    @click="getUserChange()"
                  >
                    搜索
                  </el-button>
                </div>
              </div>
            </template>
            <template slot="table-columns">
              <el-table-column prop="card_name" label="卡券名称">
                <template
                  slot-scope="scope"
                >{{scope.row.card_name}}</template>
              </el-table-column>
              <el-table-column prop="exchange_time" label="兑换时间" width="160">
                <template slot-scope="scope">{{scope.row.exchange_time | unixToDate("yyyy-MM-dd hh:mm:ss")}}</template>
              </el-table-column>
              <el-table-column prop="card_code" label="卡号"></el-table-column>
              <el-table-column label="卡密" prop="card_key"></el-table-column>
            </template>
          </en-table-layout>

          <div class="fenye">
            <div class="block">
              <el-pagination
                prev-text="上一页"
                next-text="下一页"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="inform.page_no"
                :page-size="inform.page_size"
                :page-sizes="MixinPageSizes"
                :layout="MixinTableLayout"
                background
                :total="total"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as API_Users from "@/api/users";
import * as API_member from "@/api/member";
import EnTableLayout from '../../../../ui-components/TableLayout/src/main';

export default {
  name: "usersMsg",
  components: {EnTableLayout},
  data() {
    return {
      total: 0,
      flag: false,
      activeName: "first",
      currentPage4: 4,
      formInline: {
        user: "",
        region: ""
      },
      inform: {
        page_size: 20,
        page_no: 1,
        member_id: 0,
        card_name: '',
        order_time_range: ''
      },
      userMsg: {},
      tableData: {
        data: [],
        page_no: 1,
        page_size: 20
      },
      multipleSelection: []
    };
  },
  created() {
    console.log(this.$route.query, "this.$route.query");
    console.log(this.$route)
    this.getmemberMsg();
  },
  mounted() {},
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    getmemberMsg() {
      this.inform.member_id = this.$route.query.id;
      API_member.getmembersgetInfo(this.inform.member_id).then(res => {
        this.userMsg = res;
      });
      this.getUserChange()
    },
    getUserChange() {
      if (this.inform.order_time_range) {
        const exchange_start = this.inform.order_time_range[0] / 1000
        const exchange_end = this.inform.order_time_range[1] / 1000 + 86399
        delete this.inform.order_time_range
        this.inform['exchange_start'] = exchange_start
        this.inform['exchange_end'] = exchange_end
      }
      API_Users.getUserChange(this.inform).then(res => {
        this.tableData.data = res.data;
        this.tableData.page_no = res.page_no;
        this.tableData.page_size = res.page_size;
        this.total = res.total;
      });
    },
    handleEdit(index, row) {
      this.$router.push(`/order/order-list/detail/${row.sn}`);
    },
    goorder() {
      console.log("订单");
      this.$router.push(`/combo-card-shop/order/order-list?member_id=${this.userMsg.member_id}`);
    },
    handleSizeChange(val) {
      this.inform.page_size = val;
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.inform.page_no = val;
      console.log(`当前页: ${val}`);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    }
  }
};
</script>
<style lang="scss" scoped>
@media screen and (max-device-width: 1366px) {
  .top {
    //border: 1px solid #666;
    color: #666666;
    border-radius: 10px;
    background: #fff;
  }
  .goorder {
    margin-left: 25px;
    color: #0099ff;
    font-style: normal;
    cursor: pointer;
  }
  .top .msgleft {
    float: left;
  }
  .top .msgright {
    float: left;
    margin-left: 130px;
  }
  .msgtable {
    padding-bottom: 10px;
  }
  .pad {
    .title {
      font-size: 11px;
      font-weight: bold;
      color: #333333;
    }
    padding: 5px;
  }
  .content-header {
    height: 43px;
    border-radius: 7px 7px 0 0;
    background: #E6ECF7;
    padding-left: 20px;
    line-height: 43px;
    font-size: 13px;
    font-weight: bold;
    color: #333333;
  }
}

@media screen and (min-device-width: 1440px) {
  .top {
    //border: 1px solid #666;
    color: #666666;
    border-radius: 10px;
    background: #fff;
  }
  .goorder {
    margin-left: 25px;
    color: #0099ff;
    font-style: normal;
    cursor: pointer;
  }
  .top .msgleft {
    float: left;
  }
  .top .msgright {
    float: left;
    margin-left: 130px;
  }
  .msgtable {
    padding-bottom: 10px;
  }
  .pad {
    .title {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }
    padding: 10px;
  }
  .content-header {
    height: 50px;
    border-radius: 10px 10px 0 0;
    background: #E6ECF7;
    line-height: 50px;
    padding-left: 20px;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
  }
}
</style>
